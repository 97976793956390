import HeadPagesBlock from '~/components/blocks/HeadPagesBlock'
import SubscribeFormLarge from '~/components/forms/SubscribeFormLarge'
import MainLayout from '~/components/layouts/MainLayout'
import { PressPageProps } from '~/pages/press'

import PressPost from './components/PressPost'

const Press = ({ data }: PressPageProps) => (
  <MainLayout>
    <HeadPagesBlock
      title="Press"
      description="Contact us for press opportunities:"
      descriptionLink="mailto:hello@codica.com"
      descriptionLinkName="hello@codica.com"
    />

    <PressPost data={data} />

    <div className="container container-md">
      <SubscribeFormLarge
        title="Stay up-to-date and never miss a resource"
        description="Subscribe to Codica newsletter"
      />
    </div>
  </MainLayout>
)
export default Press
