import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import { PressPageProps } from '~/pages/press'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './PressPost.module.scss'

const PressPost = ({ data }: PressPageProps) => (
  <div className="container container-md">
    <div className="row">
      {data.allStrapiPress.edges.map(({ node: post }, index) => (
        <div
          key={post.title}
          className={post.size === 'large' ? 'col-12' : 'col-12 col-md-6'}
        >
          <div
            className={cx(
              containerStyles.pressCard__post,
              containerStyles[`pressCard__${post.size}`],
            )}
          >
            <a
              target="_blank"
              aria-label={`${post.title}`}
              href={post.link as string | undefined}
              rel={post.attributes_link as string | undefined}
              title={`${post.title}`}
              className={containerStyles.pressCard__link}
            />
            {post.image && (
              <div className={containerStyles[`pressCard__image_${post.size}`]}>
                {React.isValidElement(post.image.localFile) ? (
                  post.image.localFile
                ) : (
                  <GatsbyImage
                    image={
                      getImage(
                        fileToImageLikeData(post.image.localFile),
                      ) as IGatsbyImageData
                    }
                    alt={`${post.title} | Codica`}
                    title={`${post.title}`}
                    loading={index === 0 ? 'eager' : 'lazy'}
                  />
                )}
              </div>
            )}
            <div
              className={cx(
                containerStyles.pressCard__info,
                containerStyles[`pressCard__info_${post.size}`],
              )}
            >
              {post.source && (
                <div className={containerStyles.pressCard__source}>
                  {React.isValidElement(post.source.localFile) ? (
                    post.source.localFile
                  ) : (
                    <GatsbyImage
                      image={
                        getImage(
                          fileToImageLikeData(post.source.localFile),
                        ) as IGatsbyImageData
                      }
                      alt={`${post.title}| Codica`}
                      title={`${post.title}`}
                      loading="lazy"
                    />
                  )}
                </div>
              )}
              <div className={containerStyles.pressCard__title}>
                {post.title}
              </div>
              {post.size === 'large' && (
                <div className={containerStyles.pressCard__description}>
                  {post.description}
                </div>
              )}
              <div className={containerStyles.pressCard__date}>{post.date}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default PressPost
