// extracted by mini-css-extract-plugin
export var pressCard__date = "cb_nX";
export var pressCard__description = "cb_nW";
export var pressCard__image_large = "cb_nN";
export var pressCard__image_small = "cb_nP";
export var pressCard__info = "cb_nQ";
export var pressCard__info_large = "cb_nR";
export var pressCard__info_small = "cb_nS";
export var pressCard__large = "cb_nY";
export var pressCard__link = "cb_nM";
export var pressCard__post = "cb_nL";
export var pressCard__small = "cb_nK";
export var pressCard__source = "cb_nT";
export var pressCard__title = "cb_nV";