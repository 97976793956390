import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllStrapiPress, QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Press from '~/views/Press/Press.view'

export type PressPageProps = QueryResult<StrapiPage & AllStrapiPress>

const PressPage = ({ data }: PressPageProps) => <Press data={data} />
export default PressPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Press" }) {
      ...MetaTagInformationPages
    }

    allStrapiPress(sort: { pin: DESC }) {
      edges {
        node {
          pin
          title
          description
          attributes_link
          link
          size
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          source {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          date(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`

export const Head = ({ data }: PressPageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/press/"
    />
  )
}
